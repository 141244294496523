
export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    data() {
        const data = {
            errors: [],
            selectedProduct: null,
            qty: 0,
            loading: false,
            fullList: false,
            fullListLimit: 5,
        };

        if (this.product.items) {
            data.packageUnits = null;
            const validItems = this.product.items.filter((item) => Boolean(item.product)).sort((itemA, itemB) => itemA.position - itemB.position);

            if (validItems.length <= data.fullListLimit + 1) {
                data.fullList = true;
            }

            data.groupedQty = validItems.map((item) => {
                const regularPrice = item.product.price_range.minimum_price.regular_price.value;
                const finalPrice = item.product.price_range.minimum_price.final_price.value;
                const hasDiscount = regularPrice !== finalPrice;
                const defaultMax = 9999;
                const maxQty = item.product.back_order_status ? defaultMax : item.product.only_x_left_in_stock || defaultMax;
                const maxOutOfStockQty = item.product.is_available_on_demand === null ? 0 : defaultMax;

                const packageUnits = [];
                if (item.product.packaging.packaging_unit_individual && item.product.packaging.packaging_amount_individual) {
                    packageUnits.push({
                        label: item.product.packaging.packaging_unit_individual,
                        size: item.product.packaging.packaging_amount_individual,
                    });
                }
                if (item.product.packaging.packaging_unit_package && item.product.packaging.packaging_amount_package) {
                    packageUnits.push({
                        label: item.product.packaging.packaging_unit_package,
                        size: item.product.packaging.packaging_amount_package,
                    });
                }

                const defaultUnitSize = packageUnits.length > 0 ? packageUnits[0].size : 1;

                return {
                    uid: item.product.uid,
                    qty: 0,
                    max: item.product.stock_status === 'IN_STOCK' ? maxQty : maxOutOfStockQty,
                    hasDiscount,
                    regularPrice,
                    finalPrice,
                    packageUnits,
                    selectedUnitSize: defaultUnitSize,
                };
            });
        } else {
            data.packageUnits = [];

            if (this.product.packaging.packaging_unit_individual && this.product.packaging.packaging_amount_individual) {
                data.packageUnits.push({
                    label: this.product.packaging.packaging_unit_individual,
                    size: this.product.packaging.packaging_amount_individual,
                });
            }
            if (this.product.packaging.packaging_unit_package && this.product.packaging.packaging_amount_package) {
                data.packageUnits.push({
                    label: this.product.packaging.packaging_unit_package,
                    size: this.product.packaging.packaging_amount_package,
                });
            }
        }

        const defaultMax = 9999;
        data.maxQty = this.product.back_order_status ? defaultMax : this.product.only_x_left_in_stock || defaultMax;
        data.selectedUnitSize = data.packageUnits && data.packageUnits.length > 0 ? data.packageUnits[0].size : 1;

        return data;
    },
    computed: {
        wishlistProducts() {
            if (!this.total) {
                return [];
            }

            if (!this.product.items) {
                return [
                    {
                        product: this.product,
                        qty: this.qty,
                    },
                ];
            } else {
                const products = [];
                this.product.items.forEach((item) => {
                    const simpleProductData = this.groupedQty.find((qtyItem) => qtyItem.uid === item.product.uid);
                    if (simpleProductData.qty > 0) {
                        products.push({
                            product: item.product,
                            qty: simpleProductData.qty,
                        });
                    }
                });

                return products;
            }
        },
        addToWishlistDisabled() {
            if (!this.$store.state.auth.loggedIn) {
                return true;
            }

            if (this.product.items && !this.total) {
                return true;
            }

            if (!this.product.items && this.packageUnits && this.packageUnits.length && !this.selectedUnitSize) {
                return true;
            }

            if (!this.product.items && this.qty === 0) {
                return true;
            }

            return false;
        },
        discountAmount() {
            return this.product.price_range.minimum_price.discount.amount_off;
        },
        addToCartDisabled() {
            if (!this.$store.state.auth.loggedIn) {
                return true;
            }

            if (!this.product.items && !this.finalPrice && this.qty) {
                // Simple product with 0 price, e.g. calendary, when price is 0 while qty is not 0
                return false;
            }

            return !this.total;
        },
        simpleQuantityDisabled() {
            if (!this.$store.state.auth.loggedIn) {
                return true;
            }

            if (!this.product.items && this.packageUnits && this.packageUnits.length && !this.selectedUnitSize) {
                return true;
            }

            if (this.product.stock_status === 'OUT_OF_STOCK' && this.product.is_available_on_demand) {
                return false;
            }

            return this.product.stock_status === 'OUT_OF_STOCK';
        },
        finalPrice() {
            return this.product.price_range.minimum_price.final_price.value;
        },
        regularPrice() {
            return this.product.price_range.minimum_price.regular_price.value;
        },
        simpleProducts() {
            if (this.product.items) {
                const validItems = [...this.product.items].sort((itemA, itemB) => itemA.position - itemB.position).filter((item) => Boolean(item.product));
                const listLength = this.fullList ? validItems.length : validItems.length > this.fullListLimit + 1 ? this.fullListLimit : validItems.length;

                return validItems.slice(0, listLength);
            }

            return [];
        },
        total() {
            if (this.product.items) {
                // All grouped items total price
                let totalGroupedPrice = 0;
                this.product.items.forEach((item) => {
                    const simpleProductData = this.groupedQty.find((qtyItem) => qtyItem.uid === item.product.uid);

                    if (!simpleProductData.selectedUnitSize) {
                        return;
                    }

                    totalGroupedPrice += simpleProductData.qty * simpleProductData.finalPrice;
                });

                return totalGroupedPrice > 0 ? totalGroupedPrice : null;
            } else {
                // Simple product total price
                if (!this.product.items && this.packageUnits && this.packageUnits.length && !this.selectedUnitSize) {
                    return null;
                }

                return this.finalPrice * this.qty;
            }
        },
        currency() {
            const product = this.product.items && this.product.items.length ? this.product.items[0].product : this.product;

            return product.price_range.minimum_price.final_price.currency;
        },
        showSubscription() {
            if (!this.$store.state.auth.loggedIn) {
                return false;
            }

            if (this.product.items && !this.selectedProduct) {
                return false;
            }

            const product = this.selectedProduct ? this.selectedProduct.product : this.product;

            if (product.stock_status === 'OUT_OF_STOCK' && product.is_available_on_demand) {
                return false;
            }

            return product.stock_status === 'OUT_OF_STOCK';
        },
        isHanselBadge() {
            if (this.$config.IS_HANSEL) {
                const isMedituoteCatalog = Boolean(this.$storage.getCookie('medituote-catalog'));

                return !isMedituoteCatalog;
            } else {
                return false;
            }
        },
        showFullPrice() {
            if (!this.$store.state.auth.loggedIn || !this.$store.state.auth.user) {
                return true;
            }

            return this.$store.state.auth.user.is_allowed_to_view_regular_price;
        },
    },
    methods: {
        setPackageSize(groupedItemId, packageSizeId) {
            this.groupedQty[groupedItemId].selectedUnitSize = this.groupedQty[groupedItemId].packageUnits[packageSizeId].size;
        },
        setPackageSizeSimple(packageSizeId) {
            this.selectedUnitSize = this.packageUnits[packageSizeId].size;
        },
        openList() {
            this.fullList = true;
        },
        parseError(errorMessage) {
            if (errorMessage.trim() === 'The requested qty is not available') {
                return this.$t('error.cart.qtynotavailable');
            }

            return errorMessage;
        },
        selectProduct(item) {
            this.selectedProduct = item;
            this.$emit('update', item);
        },
        async submit(recursion) {
            if (recursion?.submitter?.id !== 'addtocart-submit') {
                return;
            }

            this.loading = true;
            const isGrouped = this.product.items && this.product.items.length;
            const itemsPayload = [];
            const productNames = [];
            if (!isGrouped) {
                itemsPayload.push({
                    sku: this.product.sku,
                    quantity: this.qty,
                });
                productNames.push(this.product.name);
            } else {
                const options = [];
                this.product.items.forEach((item) => {
                    const itemData = this.groupedQty.find((qtyItem) => qtyItem.uid === item.product.uid);
                    if (itemData.qty > 0) {
                        const quantity = itemData.qty;
                        const childUid = item.product.uid;
                        const groupedDefinition = `grouped/${childUid}/${quantity}`;
                        options.push(btoa(groupedDefinition));
                        productNames.push(item.product.name);
                    }
                });

                itemsPayload.push({
                    sku: this.product.sku,
                    quantity: 1,
                    selected_options: options,
                });
            }
            /* eslint-disable camelcase */
            this.errors = [];
            try {
                const { user_errors } = await this.$store.dispatch('cart/addProduct', {
                    items: itemsPayload,
                    product: this.product,
                });
                this.errors = user_errors;
                /* eslint-enable camelcase */
                if (!this.errors.length) {
                    productNames.forEach((productName) => {
                        this.$notify.success(this.$t('cart.addedtocart.notification', { productName }));
                    });
                }
            } catch (e) {
                if (recursion) {
                    if (
                        e.message.startsWith('GraphQL error: Could not find a cart with ID') ||
                        e.message.startsWith('GraphQL error: The current user cannot perform operations on cart') ||
                        e.message.startsWith('GraphQL error: Current user does not have an active cart.') ||
                        e.message.startsWith("GraphQL error: The cart isn't active.")
                    ) {
                        this.$storage.removeCookie('cart_id');
                        await this.$store.commit('cart/setCart', {});
                        await this.$store.dispatch('cart/getCart');
                        await this.submit(true);
                    }
                } else {
                    this.$notify.error(e);
                }
            } finally {
                this.loading = false;
            }
        },
    },
};
